


import MenuEn from './en/MenuEn';
import MenuEs from './es/MenuEs';
 import HomeEn from './en/HomeEn';
import HomeEs from './es/HomeEs';
 import AboutEn from './en/AboutEn';
import AboutEs from './es/AboutEs';
 import ContactEn from './en/ContactEn';
import ContactEs from './es/ContactEs';
 
import BlogDetailsEs from './es/BlogDetailsEs';
import CategoryEs from './es/CategoryEs';

import EventsDanceEn from './en/EventsDanceEn';
import EventsDanceEs from './es/EventsDanceEs';
 import EventSpanishEn from './en/EventSpanishEn';
import EventSpanishEs from './es/EventSpanishEs'; 
 import PhotosEn from './en/PhotosEn';
import PhotosEs from './es/PhotosEs';
 import ProjectsEn from './en/ProjectsEn';
import ProjectsEs from './es/ProjectsEs';
 
import RestaHomeEn from './en/RestaHomeEn';
import RestaHomeEs from './es/RestaHomeEs';
 
import SpanishLanguageEn from './en/WebuniEn';
import SpanishLanguageEs from './es/WebuniEs';
 import SchoolsDanceEn from './en/SchoolsDanceEn';
import SchoolsDanceEs from './es/SchoolsDanceEs';
 import TeachersDanceEn from './en/TeachersDanceEn';
import TeachersDanceEs from './es/TeachersDanceEs';
 import TeacherSpanishEn from './en/TeacherSpanishEn';
import TeacherSpanishEs from './es/TeacherSpanishEs';
 import VideosEn from './en/VideosEn';
import VideosEs from './es/VideosEs';
 

import TurizoLaBachataEs from '../pages/es/lyrics/TurizoLaBachataEs';
 import LatinoDanceMoscowEs from '../pages/es/lifestyle/231228Es-latino-dance-in-Moscow';
 import TravelToSpainEs from '../pages/es/travel/231228Es-travel-spain';
 import NewYearLatamEs from '../pages/es/society/231228Es-new-year-latam';
 

const Constants= {
  en: {
    Home: HomeEn, 
    Menu:MenuEn, 
    About:AboutEn, 
    Contact:ContactEn,
    EventsDance:EventsDanceEn,
    EventSpanish:EventSpanishEn,
    Photos:PhotosEn,
    Projects:ProjectsEn,
    RestaHome:RestaHomeEn,
    SchoolsDance:SchoolsDanceEn,
    SpanishLanguage:SpanishLanguageEn,
    TeachersDance:TeachersDanceEn,
    TeacherSpanish:TeacherSpanishEn,
    Videos:VideosEn,  
  },

  es: {
    Home: HomeEs, 
    Menu:MenuEs, 
    About:AboutEs, 
    Contact:ContactEs,

    BlogDetails: BlogDetailsEs,
    Category: CategoryEs,

    EventsDance:EventsDanceEs,
    EventSpanish:EventSpanishEs,
    Photos:PhotosEs,
    Projects:ProjectsEs,
    RestaHome:RestaHomeEs,
    SpanishLanguage: SpanishLanguageEs,
    SchoolsDance:SchoolsDanceEs,
    TeachersDance:TeachersDanceEs, 
    TeacherSpanish:TeacherSpanishEs, 
    Videos:VideosEs, 


    TurizoLaBachata: TurizoLaBachataEs,
    LatinoDanceMoscow: LatinoDanceMoscowEs,
    TravelToSpain: TravelToSpainEs,
    NewYearLatam: NewYearLatamEs,

  },
  
 /*  ru: {
    Home: HomeRu, 
    Menu:MenuRu, 
    About:AboutRu, 
    Contact:ContactRu,
    EventsDance:EventsDanceRu,
    EventSpanish:EventSpanishRu,
    Photos:PhotosRu,
    Projects:ProjectsRu,
    RestaHome:RestaHomeRu,
    SchoolsDance:SchoolsDanceRu,
    SpanishLanguage:SpanishLanguageRu,
    TeachersDance:TeachersDanceRu,
    TeacherSpanish:TeacherSpanishRu, 
    Videos:VideosRu, 

    TurizoLaBachata: TurizoLaBachataRu,
    LatinoDanceMoscow: LatinoDanceMoscowRu,
    NewYearLatam: NewYearLatamRu,
    TravelToSpain: TravelToSpainRu,
  }, */
};

export default Constants;

