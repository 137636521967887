

import Menu from "./MenuMobEs";
import Footer from "./FooterEs";
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import React, { useEffect, useState } from 'react';


function FoxEventsDance() {

    return (

        <main>

            {/* <Menu /> */}

            <section
                className="hero-wrap hero-wrap-2"
                style={{ backgroundImage: 'url("/assets/img/gallery/dance-girl-man.png")' }}

            >
                <div className="overlay" />
                <div className="container">
                    <div className="row no-gutters slider-text align-items-center justify-content-center">
                        <div className="col-md-9 ftco-animate text-center">
                            <h1 className="mb-2 bread">Próximos Eventos</h1>
                            <p className="breadcrumbs">
                                <span className="mr-2">
                                    <a href=" ">
                                        Home <i className="ion-ios-arrow-forward" />
                                    </a>
                                </span>{" "}
                                <span>
                                    Eventos <i className="ion-ios-arrow-forward" />
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
            </section>


            <section className="ftco-section bg-light">
                <div className="container">
                    <div className="row">

                         {/* single */}
                         <div className="col-md-6 col-lg-4 ftco-animate">
                            <div className="blog-entry">
                                <Link to="https://vk.com/bachatamuzaparty" target="_blank" className="block-20 d-flex align-items-end"


                                    style={{ backgroundImage: 'url("/assets/img/events/muza-20-01-24.jpg")' }}

                                >
                                    <div className="meta-date text-center p-2">
                                        <span className="day">20</span>
                                        <span className="mos">Jan</span>
                                        <span className="yr">2024</span>
                                    </div>
                                </Link>
                                <div className="text bg-white p-4">
                                    <h3 className="heading">
                                        <a href="#"> Campeonato de Bachata - MUZA MOSCOW OPEN CUP | GOLD 🏆 </a>
                                    </h3>
                                    <p>
                                    Приглашаются к участию танцоры Категорий: Начинающие, Любители, Профессионалы. Дисциплины: J&J, PRO-AM, ИМПРОВИЗАЦИЯ ПАРЫ, БАТТЛЫ-СОЛО🔥
                                    
                                        </p>
                                    <div className="d-flex align-items-center mt-4">
                                        <p className="mb-0">
                                            <Link to="https://vk.com/bachatamuzaparty" target="_blank" className="btn btn-primary">

                                                Read More <span className="ion-ios-arrow-round-forward" />
                                            </Link>
                                        </p>
                                        <p className="ml-auto mb-0">
                                            <a href="#" className="mr-2">
                                                Admin
                                            </a>
                                            <a href="#" className="meta-chat">
                                                <span className="icon-chat" /> 3
                                            </a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                          {/* single */}
                        <div className="col-md-6 col-lg-4 ftco-animate">
                            <div className="blog-entry">
                                <Link to="https://vk.com/brillolatino" target="_blank" className="block-20 d-flex align-items-end"


                                    style={{ backgroundImage: 'url("/assets/img/events/brillo-latino-14-01-24.jpg")' }}

                                >
                                    <div className="meta-date text-center p-2">
                                        <span className="day">14</span>
                                        <span className="mos">Jan</span>
                                        <span className="yr">2024</span>
                                    </div>
                                </Link>
                                <div className="text bg-white p-4">
                                    <h3 className="heading">
                                        <a href="#"> Fiesta de Nochevieja en el bar “Club Liberty” </a>
                                    </h3>
                                    <p>

                                        21.00-23.45 танцуем под музыку DJ Roberto Suarez-Curra 🎶
                                        🎵Формат 4 сальсы/2 бачаты
                                    </p>
                                    <div className="d-flex align-items-center mt-4">
                                        <p className="mb-0">
                                            <Link to="https://vk.com/brillolatino" target="_blank" className="btn btn-primary">

                                                Read More <span className="ion-ios-arrow-round-forward" />
                                            </Link>
                                        </p>
                                        <p className="ml-auto mb-0">
                                            <a href="#" className="mr-2">
                                                Admin
                                            </a>
                                            <a href="#" className="meta-chat">
                                                <span className="icon-chat" /> 3
                                            </a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* single */}
                        <div className="col-md-6 col-lg-4 ftco-animate">
                            <div className="blog-entry">
                                <Link to="https://vk.com/shine_bachata" target="_blank" className="block-20 d-flex align-items-end"


                                    style={{ backgroundImage: 'url("/assets/img/events/shine-b.jpg")' }}

                                >
                                    <div className="meta-date text-center p-2">
                                        <span className="day">03-06</span>
                                        <span className="mos">Jan</span>
                                        <span className="yr">2024</span>
                                    </div>
                                </Link>
                                <div className="text bg-white p-4">
                                    <h3 className="heading">
                                        <a href="#">SHINE FESTIVAL ✨ 3-6 ЯНВАРЯ 2024 В МОСКВЕ</a>
                                    </h3>
                                    <p>
                                    Уже 5-ый год крутые танцевальные мероприятия и как всегда приглашаем на январские каникулы на фестиваль, где собираются участники из более, чем 50 городов и 3 стран
                                    </p>
                                    <div className="d-flex align-items-center mt-4">
                                        <p className="mb-0">
                                            <Link to="https://vk.com/shine_bachata" target="_blank" className="btn btn-primary">

                                                Read More <span className="ion-ios-arrow-round-forward" />
                                            </Link>
                                        </p>
                                        <p className="ml-auto mb-0">
                                            <a href="#" className="mr-2">
                                                Admin
                                            </a>
                                            <a href="#" className="meta-chat">
                                                <span className="icon-chat" /> 3
                                            </a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* single */}
                        <div className="col-md-6 col-lg-4 ftco-animate">
                            <div className="blog-entry">
                                <Link to="https://vk.com/onlysocial" target="_blank" className="block-20 d-flex align-items-end"


                                    style={{ backgroundImage: 'url("/assets/img/events/fiesta-cubana.jpg")' }}

                                >
                                    <div className="meta-date text-center p-2">
                                        <span className="day">04</span>
                                        <span className="mos">Jan</span>
                                        <span className="yr">2024</span>
                                    </div>
                                </Link>
                                <div className="text bg-white p-4">
                                    <h3 className="heading">
                                        <a href="#">Ночная вечеринка Fiesta Cuban</a>
                                    </h3>
                                    <p>
                                        Школа социальных танцев "Salsa Social" приглашает в субботу, 4 января, на ночную вечеринку Fiesta Cuban в новой школе Loft Salsa Social!

                                        Ждем учеников, друзей и всех желающих потанцевать
                                    </p>
                                    <div className="d-flex align-items-center mt-4">
                                        <p className="mb-0">
                                            <Link to="https://vk.com/onlysocial" target="_blank" className="btn btn-primary">

                                                Read More <span className="ion-ios-arrow-round-forward" />
                                            </Link>
                                        </p>
                                        <p className="ml-auto mb-0">
                                            <a href="#" className="mr-2">
                                                Admin
                                            </a>
                                            <a href="#" className="meta-chat">
                                                <span className="icon-chat" /> 3
                                            </a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* single */}
                        <div className="col-md-6 col-lg-4 ftco-animate">
                            <div className="blog-entry">
                                <Link to="https://vk.com/shtabpanchovilla" target="_blank" className="block-20 d-flex align-items-end"

                                    style={{ backgroundImage: 'url("/assets/img/events/pancho-villa-new-year.gif")' }}>

                                    <div className="meta-date text-center p-2">
                                        <span className="day">31</span>
                                        <span className="mos">Dec</span>
                                        <span className="yr">2023</span>
                                    </div>
                                </Link>
                                <div className="text bg-white p-4">
                                    <h3 className="heading">
                                        <a href="#">ВСТРЕЧАЙТЕ НОВЫЙ ГОД 2024 В PANCHO VILLA!</a>
                                    </h3>
                                    <p>
                                        В программе традиционное поздравление Деда Мороза!🎅🏻
                                        Живая музыка кавер-группы Arizona и самый крутой DJ VITO!

                                        Один билет стоит 5000₽, c посадкой в баре — 4000₽🎁


                                    </p>
                                    <div className="d-flex align-items-center mt-4">
                                        <p className="mb-0">
                                            <Link to="https://vk.com/shtabpanchovilla" target="_blank" className="btn btn-primary">

                                                Read More <span className="ion-ios-arrow-round-forward" />
                                            </Link>
                                        </p>
                                        <p className="ml-auto mb-0">
                                            <a href="#" className="mr-2">
                                                Admin
                                            </a>
                                            <a href="#" className="meta-chat">
                                                <span className="icon-chat" /> 3
                                            </a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* single */}
                        <div className="col-md-6 col-lg-4 ftco-animate">
                            <div className="blog-entry">
                                <Link to="https://vk.com/newyearstarsparty" target="_blank" className="block-20 d-flex align-items-end"

                                    style={{ backgroundImage: 'url("/assets/img/events/bachata-stars-31-12.jpg")' }}>

                                    <div className="meta-date text-center p-2">
                                        <span className="day">31</span>
                                        <span className="mos">Dec</span>
                                        <span className="yr">2023</span>
                                    </div>
                                </Link>
                                <div className="text bg-white p-4">
                                    <h3 className="heading">
                                        <a href="#"> Самый масштабный танцевальный Новый Год! 🔥</a>
                                    </h3>
                                    <p>
                                        Никаких оливье, сидения на диване, нудных передач и отъедания боков!🙅🏼‍♂ Только танцы - только хардкор!!!🥳💃🕺
                                    </p>
                                    <div className="d-flex align-items-center mt-4">
                                        <p className="mb-0">
                                            <Link to="https://vk.com/newyearstarsparty" target="_blank" className="btn btn-primary">

                                                Read More <span className="ion-ios-arrow-round-forward" />
                                            </Link>
                                        </p>
                                        <p className="ml-auto mb-0">
                                            <a href="#" className="mr-2">
                                                Admin
                                            </a>
                                            <a href="#" className="meta-chat">
                                                <span className="icon-chat" /> 3
                                            </a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 ftco-animate">
                            <div className="blog-entry">
                                <Link to="https://vk.com/fe.liz.latino" target="_blank" className="block-20 d-flex align-items-end"

                                    style={{ backgroundImage: 'url("/assets/img/events/feliz-dance-31-12.jpg")' }}>

                                    <div className="meta-date text-center p-2">
                                        <span className="day">31</span>
                                        <span className="mos">Dec</span>
                                        <span className="yr">2023</span>
                                    </div>
                                </Link>
                                <div className="text bg-white p-4">
                                    <h3 className="heading">
                                        <a href="#"> Бачатный Новый 2024 Год вместе с FeLIZ🤩
                                        </a>
                                    </h3>
                                    <p>
                                        В саму новогоднюю ночь мы делаем праздник для нашей бачатной семьи🥳🥳

                                        🔶Праздничная Новогодняя программа!😍
                                        🔶Живой вокал!🥳
                                        🔶Тайный Санта 🎅🎄
                                    </p>
                                    <div className="d-flex align-items-center mt-4">
                                        <p className="mb-0">
                                            <Link to="https://vk.com/fe.liz.latino" target="_blank" className="btn btn-primary">

                                                Read More <span className="ion-ios-arrow-round-forward" />
                                            </Link>
                                        </p>
                                        <p className="ml-auto mb-0">
                                            <a href="#" className="mr-2">
                                                Admin
                                            </a>
                                            <a href="#" className="meta-chat">
                                                <span className="icon-chat" /> 3
                                            </a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>



                        <div className="col-md-6 col-lg-4 ftco-animate">
                            <div className="blog-entry">
                                <Link to="https://vk.com/papas.moscow" target="_blank" className="block-20 d-flex align-items-end"

                                    style={{ backgroundImage: 'url("/assets/img/events/papas-bar-31-12.jpg")' }}>

                                    <div className="meta-date text-center p-2">
                                        <span className="day">31</span>
                                        <span className="mos">Dec</span>
                                        <span className="yr">2023</span>
                                    </div>
                                </Link>
                                <div className="text bg-white p-4">
                                    <h3 className="heading">
                                        <a href="#"> НОВЫЙ ГОД В PAPA’S
                                            | ПРЕДНОВОГОДНИЙ КОНКУРС🎄
                                        </a>
                                    </h3>
                                    <p>
                                        Мы встретим вас в теплой атмосфере, в компании Деда Мороза и Снегурочки, машиниста-ведущего и очаровательных проводниц мы встретим праздник года🎅🏻
                                    </p>
                                    <div className="d-flex align-items-center mt-4">
                                        <p className="mb-0">
                                            <Link to="https://vk.com/papas.moscow" target="_blank" className="btn btn-primary">
                                                Read More <span className="ion-ios-arrow-round-forward" />
                                            </Link>
                                        </p>
                                        <p className="ml-auto mb-0">
                                            <a href="#" className="mr-2">
                                                Admin
                                            </a>
                                            <a href="#" className="meta-chat">
                                                <span className="icon-chat" /> 3
                                            </a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-4 ftco-animate">
                            <div className="blog-entry">
                                <Link to="https://vk.com/shishabar" target="_blank" className="block-20 d-flex align-items-end"

                                    style={{ backgroundImage: 'url("/assets/img/events/shushas-31-12.jpg")' }}>

                                    <div className="meta-date text-center p-2">
                                        <span className="day">31</span>
                                        <span className="mos">Dec</span>
                                        <span className="yr">2023</span>
                                    </div>
                                </Link>
                                <div className="text bg-white p-4">
                                    <h3 className="heading">
                                        <a href="#"> Новогоднее путешествие в поднебесную в SHUSHAS
                                        </a>
                                    </h3>
                                    <p>
                                        31 ДЕКАБРЯ 2023: Новогоднее путешествие в поднебесную в SHUSHAS на Пушкинской!
                                        from 1.000 ₽ 🎅🏻
                                    </p>
                                    <div className="d-flex align-items-center mt-4">
                                        <p className="mb-0">
                                            <Link to="https://vk.com/shishabar" target="_blank" className="btn btn-primary">
                                                Read More <span className="ion-ios-arrow-round-forward" />
                                            </Link>
                                        </p>
                                        <p className="ml-auto mb-0">
                                            <a href="#" className="mr-2">
                                                Admin
                                            </a>
                                            <a href="#" className="meta-chat">
                                                <span className="icon-chat" /> 3
                                            </a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="col-md-6 col-lg-4 ftco-animate">
                            <div className="blog-entry">
                                <Link to="https://vk.com/salsasocial" target="_blank" className="block-20 d-flex align-items-end"

                                    style={{ backgroundImage: 'url("/assets/img/events/salsa-social-31-12a.jpg")' }}>

                                    <div className="meta-date text-center p-2">
                                        <span className="day">31</span>
                                        <span className="mos">Dec</span>
                                        <span className="yr">2023</span>
                                    </div>
                                </Link>
                                <div className="text bg-white p-4">
                                    <h3 className="heading">
                                        <a href="#"> Новогодняя ночь с Salsa Social 🥳💃🕺
                                        </a>
                                    </h3>
                                    <p>
                                        В эту ночь у нас будут играть 5 диджеев в двух залах ( сальса, реггетон, меренге ). Так же будет работать ведущий, будут зажигательные мастер-классы, фотограф и много другое.
                                    </p>
                                    <div className="d-flex align-items-center mt-4">
                                        <p className="mb-0">
                                            <Link to="https://vk.com/salsasocial" target="_blank" className="btn btn-primary">
                                                Read More <span className="ion-ios-arrow-round-forward" />
                                            </Link>
                                        </p>
                                        <p className="ml-auto mb-0">
                                            <a href="#" className="mr-2">
                                                Admin
                                            </a>
                                            <a href="#" className="meta-chat">
                                                <span className="icon-chat" /> 3
                                            </a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="col-md-6 col-lg-4 ftco-animate">
                            <div className="blog-entry">
                                <Link to="https://vk.com/libertybarmsk" target="_blank" className="block-20 d-flex align-items-end"

                                    style={{ backgroundImage: 'url("/assets/img/events/liberty-31-12.jpg")' }}>

                                    <div className="meta-date text-center p-2">
                                        <span className="day">31</span>
                                        <span className="mos">Dec</span>
                                        <span className="yr">2023</span>
                                    </div>
                                </Link>
                                <div className="text bg-white p-4">
                                    <h3 className="heading">
                                        <a href="#"> Новый год в Либерти - это всегда карнавал и феерия! 🔥
                                        </a>
                                    </h3>
                                    <p>
                                        В программе живой концерт со специальной программой от Los Latinos.💃
                                        Интерактивное шоу, диджеи до утра и атмосфера латиноамериканской фиесты!
                                    </p>
                                    <div className="d-flex align-items-center mt-4">
                                        <p className="mb-0">
                                            <Link to="https://vk.com/libertybarmsk" target="_blank" className="btn btn-primary">
                                                Read More <span className="ion-ios-arrow-round-forward" />
                                            </Link>
                                        </p>
                                        <p className="ml-auto mb-0">
                                            <a href="#" className="mr-2">
                                                Admin
                                            </a>
                                            <a href="#" className="meta-chat">
                                                <span className="icon-chat" /> 3
                                            </a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="col-md-6 col-lg-4 ftco-animate">
                            <div className="blog-entry">
                                <Link to="https://vk.com/casa.agave" target="_blank" className="block-20 d-flex align-items-end"

                                    style={{ backgroundImage: 'url("/assets/img/events/casa-agave-24-12.jpg")' }}>

                                    <div className="meta-date text-center p-2">
                                        <span className="day">24</span>
                                        <span className="mos">Dec</span>
                                        <span className="yr">2023</span>
                                    </div>
                                </Link>
                                <div className="text bg-white p-4">
                                    <h3 className="heading">
                                        <a href="#"> CHRISTMAS PARTYControl de calidad - CASA AGAVE
                                        </a>
                                    </h3>
                                    <p>
                                        РОЖДЕСТВО – ВОЛШЕБНОЕ ВРЕМЯ, КОГДА САМЫЕ ЗАВЕТНЫЕ ЖЕЛАНИЯ ИСПОЛНЯЮТСЯ, И МЫ СТАНОВИМСЯ СЧАСТЛИВЕЕ!
                                        ПРИГЛАШАЕМ ПОТАНЦЕВАТЬ
                                    </p>
                                    <div className="d-flex align-items-center mt-4">
                                        <p className="mb-0">
                                            <Link to="https://vk.com/casa.agave" target="_blank" className="btn btn-primary">
                                                Read More <span className="ion-ios-arrow-round-forward" />
                                            </Link>

                                        </p>
                                        <p className="ml-auto mb-0">
                                            <a href="#" className="mr-2">
                                                Admin
                                            </a>
                                            <a href="#" className="meta-chat">
                                                <span className="icon-chat" /> 3
                                            </a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-4 ftco-animate">
                            <div className="blog-entry">
                                <Link to="https://vk.com/lima_restaurant" target="_blank" className="block-20 d-flex align-items-end"

                                    style={{ backgroundImage: 'url("/assets/img/events/lima-navidad.jpg")' }}>

                                    <div className="meta-date text-center p-2">
                                        <span className="day">23</span>
                                        <span className="mos">Dec</span>
                                        <span className="yr">2023</span>
                                    </div>
                                </Link>
                                <div className="text bg-white p-4">
                                    <h3 className="heading">
                                        <a href="#">Рождество в Лиме! Живая музыка</a>
                                    </h3>
                                    <p>
                                        Cпешл-меню, DJ, конкурс и в подарок всем гостям вечера горячий шоколад и паннетон!
                                        Разыграем рождественские корзины по-перуански и сертификат на ужин в Лиме!


                                    </p>
                                    <div className="d-flex align-items-center mt-4">
                                        <p className="mb-0">
                                            <Link to="https://vk.com/lima_restaurant" target="_blank" className="btn btn-primary">
                                                Read More <span className="ion-ios-arrow-round-forward" />
                                            </Link>
                                        </p>
                                        <p className="ml-auto mb-0">
                                            <a href="#" className="mr-2">
                                                Admin
                                            </a>
                                            <a href="#" className="meta-chat">
                                                <span className="icon-chat" /> 3
                                            </a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </section>


            <Footer />


        </main >



    );

}

export default FoxEventsDance;
