

 
import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
 
import Constants from './components/Constants';


function App () {

  /* const [language, setLanguage] = useState('en'); */
  const [currentPage, setCurrentPage] = useState('Home');

   /* 000000 Idioma del navegador  */
  const browserLang = ('es'  || window.navigator.language ).substr(0, 2);
  const [language, setLanguage] = useState (browserLang);
  console.log('language:', language); 
  /* 000000 */

  const changeLanguage = (newLanguage) => {
    setLanguage(newLanguage);
  };

  const navigateTo = (page) => {
    setCurrentPage(page);
  };

  const CurrentLanguageComponents = Constants[language];

  return (

    <Router>

      <div>
        
        <CurrentLanguageComponents.Menu changeLanguage={changeLanguage} navigateTo={navigateTo} />

        <Routes>
          <Route path="/" element={<CurrentLanguageComponents.Home />} />
          <Route path="/about" element={<CurrentLanguageComponents.Home />} />
          <Route path="/contact" element={<CurrentLanguageComponents.Contact />} />

          <Route path="/blog-details" element={<CurrentLanguageComponents.BlogDetails/>} />
          <Route path="/category" element={<CurrentLanguageComponents.Category/>} />

          <Route path="/" element={<CurrentLanguageComponents.EventsDance/>} />
          <Route path="/events-spanish" element={<CurrentLanguageComponents.EventSpanish/>} />
          <Route path="/gallery" element={<CurrentLanguageComponents.Photos />} />
          <Route path="/projects" element={<CurrentLanguageComponents.Projects />} />
          <Route path="/restaurants" element={<CurrentLanguageComponents.RestaHome />} />
          <Route path="/spanish-courses" element={<CurrentLanguageComponents.SpanishLanguage />} />
          <Route path="/schools-dance" element={<CurrentLanguageComponents.SchoolsDance />} />
          <Route path="/teachers-spanish" element={<CurrentLanguageComponents.TeacherSpanish />} />
          <Route path="/teachers-dance" element={<CurrentLanguageComponents.TeachersDance />} />
          <Route path="/videos" element={<CurrentLanguageComponents.Videos />} />


          <Route path="/la-bachata-manuel-turizo" element={<CurrentLanguageComponents.TurizoLaBachata />} />
          <Route path="/latino-dance-in-Moscow" element={<CurrentLanguageComponents.LatinoDanceMoscow />} />
          <Route path="/travel-to-spain" element={<CurrentLanguageComponents.TravelToSpain />} />
          <Route path="/new-year-Latam" element={<CurrentLanguageComponents.NewYearLatam />} />

        </Routes>

 

      </div>


    </Router>


  );
}

export default App;



 