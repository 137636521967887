


import Menu from "./MenuEn";
import Footer from "./FooterEn";
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import React, { useEffect, useState } from 'react';

/*  import 'lightgallery.js/dist/css/lightgallery.min.css'; */
/* import 'lg-zoom/dist/lg-zoom.min.css';
import 'lg-thumbnail/dist/lg-thumbnail.min.css';  */
/* import {lightGallery} from 'lightgallery/react'; */
/* import lgZoom from 'lg-zoom/dist/zoom.min';
import lgThumbnail from 'lg-thumbnail/dist/thumbnail.min'; */

/* import 'lightgallery.js/dist/css/lightgallery.css';  
 
import LightGallery from 'lightgallery/react'; */

/* import 'lightgallery.js/dist/css/lightgallery.css'; */
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-thumbnail.css';
import 'lightgallery/css/lg-autoplay.css';
import 'lightgallery/css/lg-video.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-fullscreen.css';
import lightGallery from 'lightgallery';

/* import 'lg-zoom/dist/lg-zoom.min.js'; */


function PhotosenEn() {

    useEffect(() => {
        const lightGalleryInit = () => {
            const gallery = document.getElementById('lightgallery');
            if (gallery) {
                lightGallery(gallery, {
                    /* mode: 'lg-slide', */
                   /*  mode: 'lg-zoom', */
                    thumbnail: false,
                    download: true,
                    zoom: false,
                    fullScreen: true,
                    autoplay: false,
                    share: false,
                    hash: false,
                    videoMaxWidth: '855px',
                });
            }
        };

        lightGalleryInit();
    }, []);


    return (

        <main>

             {/* <Menu /> */} 

            <div className="site-wrap">

                {/*    <div className="site-mobile-menu">
                    <div className="site-mobile-menu-header">
                        <div className="site-mobile-menu-close mt-3">
                            <span className="icon-close2 js-menu-toggle" />
                        </div>
                    </div>
                    <div className="site-mobile-menu-body" />
                </div>
 */}

                <header className="site-navbar py-3" role="banner">
                    <div className="container-fluid">
                        <div className="row align-items-center">

                            {/*   <div className="col-6 col-xl-2" data-aos="fade-down">
                                <h1 className="mb-0">
                                    <a href=" " className="text-white h2 mb-0">
                                        LatinoPhoto
                                    </a>
                                </h1>
                            </div> */}


                            <div
                                className="col-10 col-md-8 d-none d-xl-block"
                                data-aos="fade-down"
                            >
                                <nav
                                    className="site-navigation position-relative text-right text-lg-center"
                                    role="navigation"
                                >
                                    <ul className="site-menu js-clone-nav mx-auto d-none d-lg-block">
                                        <li>
                                            <a href=" ">Home</a>
                                        </li>
                                        <li className="has-children active">
                                            <a href="single.html">Gallery</a>
                                            <ul className="dropdown">
                                                <li>
                                                    <a href="#">Nature</a>
                                                </li>
                                                <li>
                                                    <a href="#">Portrait</a>
                                                </li>
                                                <li>
                                                    <a href="#">People</a>
                                                </li>
                                                <li>
                                                    <a href="#">Architecture</a>
                                                </li>
                                                <li>
                                                    <a href="#">Animals</a>
                                                </li>
                                                <li>
                                                    <a href="#">Sports</a>
                                                </li>
                                                <li>
                                                    <a href="#">Travel</a>
                                                </li>
                                                <li className="has-children">
                                                    <a href="#">Sub Menu</a>
                                                    <ul className="dropdown">
                                                        <li>
                                                            <a href="#">Menu One</a>
                                                        </li>
                                                        <li>
                                                            <a href="#">Menu Two</a>
                                                        </li>
                                                        <li>
                                                            <a href="#">Menu Three</a>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <a href="services.html">Services</a>
                                        </li>
                                        <li>
                                            <a href="about.html">About</a>
                                        </li>
                                        <li>
                                            <a href="contact.html">Contact</a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>


                            <div className="col-6 col-xl-2 text-right" data-aos="fade-down">


                                {/*   <div className="d-none d-xl-inline-block">
                                    <ul
                                        className="site-menu js-clone-nav ml-auto list-unstyled d-flex text-right mb-0"
                                        data-class="social"
                                    >
                                        <li>
                                            <a href="#" className="pl-0 pr-3">
                                                <span className="icon-facebook" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" className="pl-3 pr-3">
                                                <span className="icon-twitter" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" className="pl-3 pr-3">
                                                <span className="icon-instagram" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" className="pl-3 pr-3">
                                                <span className="icon-youtube-play" />
                                            </a>
                                        </li>
                                    </ul>
                                </div> */}


                                <div
                                    className="d-inline-block d-xl-none ml-md-0 mr-auto py-3"
                                    style={{ position: "relative", top: 3 }}
                                >
                                    <a
                                        href="#"
                                        className="site-menu-toggle js-menu-toggle text-black"
                                    >
                                        <span className="icon-menu h3" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>


                <div className="site-section" data-aos="fade">
                    <div className="container-fluid">
                        <div className="row justify-content-center">
                            <div className="col-md-7">
                                <div className="row mb-5">
                                    <div className="col-12 ">
                                        <h2 className="site-section-heading text-center">
                                            Gallery
                                        </h2>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="row" id="lightgallery">


                            <div
                                className="col-4 col-sm-4 col-md-4 col-lg-3 col-xl-3 item"
                                data-aos="fade"
                                data-src="/assets/img/party/fiesta-chicas.png"
                                data-sub-html="<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolor doloremque hic excepturi fugit, sunt impedit fuga tempora, ad amet aliquid?</p>"
                            >
                                <a href="#">
                                    <img
                                        src="/assets/img/party/fiesta-chicas.png"
                                        alt="IMage"
                                        className="img-fluid"
                                    />
                                </a>
                            </div>


                            <div
                                className="col-4 col-sm-4 col-md-4 col-lg-3 col-xl-3 item"
                                data-aos="fade"
                                data-src="/assets/img/party/fiesta-chicas2.png"
                                data-sub-html="<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolor doloremque hic excepturi fugit, sunt impedit fuga tempora, ad amet aliquid?</p>"
                            >
                                <a href="#">
                                    <img
                                        src="/assets/img/party/fiesta-chicas2.png"
                                        alt="IMage"
                                        className="img-fluid"
                                    />
                                </a>
                            </div>

                            <div
                                className="col-4 col-sm-4 col-md-4 col-lg-3 col-xl-3 item"
                                data-aos="fade"
                                data-src="/assets/img/party/girfriends-fiesta.jpg"
                                data-sub-html="<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolor doloremque hic excepturi fugit, sunt impedit fuga tempora, ad amet aliquid?</p>"
                            >
                                <a href="#">
                                    <img
                                        src="/assets/img/party/girfriends-fiesta.jpg"
                                        alt="IMage"
                                        className="img-fluid"
                                    />
                                </a>
                            </div>

                            <div
                                className="col-4 col-sm-4 col-md-4 col-lg-3 col-xl-3 item"
                                data-aos="fade"
                                data-src="/assets/img/party/mujeres-sonrientes-fiesta.jpg"
                                data-sub-html="<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolor doloremque hic excepturi fugit, sunt impedit fuga tempora, ad amet aliquid?</p>"
                            >
                                <a href="#">
                                    <img
                                        src="/assets/img/party/mujeres-sonrientes-fiesta.jpg"
                                        alt="IMage"
                                        className="img-fluid"
                                    />
                                </a>
                            </div>

                            <div
                                className="col-4 col-sm-4 col-md-4 col-lg-3 col-xl-3 item"
                                data-aos="fade"
                                data-src="/assets/img/party/vida-nocturna.jpg"
                                data-sub-html="<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolor doloremque hic excepturi fugit, sunt impedit fuga tempora, ad amet aliquid?</p>"
                            >
                                <a href="#">
                                    <img
                                        src="/assets/img/party/vida-nocturna.jpg"
                                        alt="IMage"
                                        className="img-fluid"
                                    />
                                </a>
                            </div>

                            <div
                                className="col-4 col-sm-4 col-md-4 col-lg-3 col-xl-3 item"
                                data-aos="fade"
                                data-src="/assets/img/party/vida-nocturna-gente-bailando.jpg"
                                data-sub-html="<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolor doloremque hic excepturi fugit, sunt impedit fuga tempora, ad amet aliquid?</p>"
                            >
                                <a href="#">
                                    <img
                                        src="/assets/img/party/vida-nocturna-gente-bailando.jpg"
                                        alt="IMage"
                                        className="img-fluid"
                                    />
                                </a>
                            </div>

                            <div
                                className="col-4 col-sm-4 col-md-4 col-lg-3 col-xl-3 item"
                                data-aos="fade"
                                data-src="/assets/img/party/vida-nocturna-gente-bailando-club.jpg"
                                data-sub-html="<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolor doloremque hic excepturi fugit, sunt impedit fuga tempora, ad amet aliquid?</p>"
                            >
                                <a href="#">
                                    <img
                                        src="/assets/img/party/vida-nocturna-gente-bailando-club.jpg"
                                        alt="IMage"
                                        className="img-fluid"
                                    />
                                </a>
                            </div>

                            <div
                                className="col-4 col-sm-4 col-md-4 col-lg-3 col-xl-3 item"
                                data-aos="fade"
                                data-src="/assets/img/party/post-06.jpg"
                                data-sub-html="<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolor doloremque hic excepturi fugit, sunt impedit fuga tempora, ad amet aliquid?</p>"
                            >
                                <a href="#">
                                    <img
                                        src="/assets/img/party/post-06.jpg"
                                        alt="IMage"
                                        className="img-fluid"
                                    />
                                </a>
                            </div>

                            <div
                                className="col-4 col-sm-4 col-md-4 col-lg-3 col-xl-3 item"
                                data-aos="fade"
                                data-src="/assets/img/party/post-07.jpg"
                                data-sub-html="<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolor doloremque hic excepturi fugit, sunt impedit fuga tempora, ad amet aliquid?</p>"
                            >
                                <a href="#">
                                    <img
                                        src="/assets/img/party/post-07.jpg"
                                        alt="IMage"
                                        className="img-fluid"
                                    />
                                </a>
                            </div>

                            <div
                                className="col-4 col-sm-4 col-md-4 col-lg-3 col-xl-3 item"
                                data-aos="fade"
                                data-src="/assets/img/party/post-50.jpg"
                                data-sub-html="<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolor doloremque hic excepturi fugit, sunt impedit fuga tempora, ad amet aliquid?</p>"
                            >
                                <a href="#">
                                    <img
                                        src="/assets/img/party/post-50.jpg"
                                        alt="IMage"
                                        className="img-fluid"
                                    />
                                </a>
                            </div>

                            <div
                                className="col-4 col-sm-4 col-md-4 col-lg-3 col-xl-3 item"
                                data-aos="fade"
                                data-src="/assets/img/party/post-22.jpg"
                                data-sub-html="<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolor doloremque hic excepturi fugit, sunt impedit fuga tempora, ad amet aliquid?</p>"
                            >
                                <a href="#">
                                    <img
                                        src="/assets/img/party/post-22.jpg"
                                        alt="IMage"
                                        className="img-fluid"
                                    />
                                </a>
                            </div>

                            <div
                                className="col-4 col-sm-4 col-md-4 col-lg-3 col-xl-3 item"
                                data-aos="fade"
                                data-src="/assets/img/party/post-32.jpg"
                                data-sub-html="<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolor doloremque hic excepturi fugit, sunt impedit fuga tempora, ad amet aliquid?</p>"
                            >
                                <a href="#">
                                    <img
                                        src="/assets/img/party/post-32.jpg"
                                        alt="IMage"
                                        className="img-fluid"
                                    />
                                </a>
                            </div>


                            <div
                                className="col-4 col-sm-4 col-md-4 col-lg-3 col-xl-3 item"
                                data-aos="fade"
                                data-src="/assets/img/photosen/big-images/nature_big_1.jpg"
                                data-sub-html="<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolor doloremque hic excepturi fugit, sunt impedit fuga tempora, ad amet aliquid?</p>"
                            >
                                <a href="#">
                                    <img
                                        src="/assets/img/photosen/nature_small_1.jpg"
                                        alt="IMage"
                                        className="img-fluid"
                                    />
                                </a>
                            </div>

                            <div
                                className="col-4 col-sm-4 col-md-4 col-lg-3 col-xl-3 item"
                                data-aos="fade"
                                data-src="/assets/img/photosen/big-images/nature_big_2.jpg"
                                data-sub-html="<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laboriosam omnis quaerat molestiae, praesentium. Ipsam, reiciendis. Aut molestiae animi earum laudantium.</p>"
                            >
                                <a href="#">
                                    <img
                                        src="/assets/img/photosen/nature_small_2.jpg"
                                        alt="IMage"
                                        className="img-fluid"
                                    />
                                </a>
                            </div>

                            <div
                                className="col-4 col-sm-4 col-md-4 col-lg-3 col-xl-3 item"
                                data-aos="fade"
                                data-src="/assets/img/photosen/big-images/nature_big_3.jpg"
                                data-sub-html="<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quidem reiciendis, dolorum illo temporibus culpa eaque dolore rerum quod voluptate doloribus.</p>"
                            >
                                <a href="#">
                                    <img
                                        src="/assets/img/photosen/nature_small_3.jpg"
                                        alt="IMage"
                                        className="img-fluid"
                                    />
                                </a>
                            </div>

                            <div
                                className="col-4 col-sm-4 col-md-4 col-lg-3 col-xl-3 item"
                                data-aos="fade"
                                data-src="/assets/img/photosen/big-images/nature_big_4.jpg"
                                data-sub-html="<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Enim perferendis quae iusto omnis praesentium labore tempore eligendi quo corporis sapiente.</p>"
                            >
                                <a href="#">
                                    <img
                                        src="/assets/img/photosen/nature_small_4.jpg"
                                        alt="IMage"
                                        className="img-fluid"
                                    />
                                </a>
                            </div>

                            <div
                                className="col-4 col-sm-4 col-md-4 col-lg-3 col-xl-3 item"
                                data-aos="fade"
                                data-src="/assets/img/photosen/big-images/nature_big_5.jpg"
                                data-sub-html="<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Saepe, voluptatum voluptate tempore aliquam, dolorem distinctio. In quas maiores tenetur sequi.</p>"
                            >
                                <a href="#">
                                    <img
                                        src="/assets/img/photosen/nature_small_5.jpg"
                                        alt="IMage"
                                        className="img-fluid"
                                    />
                                </a>
                            </div>

                            <div
                                className="col-4 col-sm-4 col-md-4 col-lg-3 col-xl-3 item"
                                data-aos="fade"
                                data-src="/assets/img/photosen/big-images/nature_big_6.jpg"
                                data-sub-html="<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Rerum cum culpa blanditiis illum, voluptatum iusto quisquam mollitia debitis quaerat maiores?</p>"
                            >
                                <a href="#">
                                    <img
                                        src="/assets/img/photosen/nature_small_6.jpg"
                                        alt="IMage"
                                        className="img-fluid"
                                    />
                                </a>
                            </div>

                            <div
                                className="col-4 col-sm-4 col-md-4 col-lg-3 col-xl-3 item"
                                data-aos="fade"
                                data-src="/assets/img/photosen/big-images/nature_big_7.jpg"
                                data-sub-html="<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Asperiores similique impedit possimus, laboriosam enim at placeat nihil voluptatibus voluptate hic!</p>"
                            >
                                <a href="#">
                                    <img
                                        src="/assets/img/photosen/nature_small_7.jpg"
                                        alt="IMage"
                                        className="img-fluid"
                                    />
                                </a>
                            </div>

                            <div
                                className="col-4 col-sm-4 col-md-4 col-lg-3 col-xl-3 item"
                                data-aos="fade"
                                data-src="/assets/img/photosen/big-images/nature_big_8.jpg"
                                data-sub-html="<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quam vitae sed cum mollitia itaque soluta laboriosam eaque sit ratione, aliquid.</p>"
                            >
                                <a href="#">
                                    <img
                                        src="/assets/img/photosen/nature_small_8.jpg"
                                        alt="IMage"
                                        className="img-fluid"
                                    />
                                </a>
                            </div>

                            <div
                                className="col-4 col-sm-4 col-md-4 col-lg-3 col-xl-3 item"
                                data-aos="fade"
                                data-src="/assets/img/photosen/big-images/nature_big_9.jpg"
                                data-sub-html="<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Itaque officiis magnam, facilis nam eos perspiciatis eligendi pariatur numquam debitis quos!</p>"
                            >
                                <a href="#">
                                    <img
                                        src="/assets/img/photosen/nature_small_9.jpg"
                                        alt="IMage"
                                        className="img-fluid"
                                    />
                                </a>
                            </div>

                            <div
                                className="col-4 col-sm-4 col-md-4 col-lg-3 col-xl-3 item"
                                data-aos="fade"
                                data-src="/assets/img/photosen/big-images/nature_big_1.jpg"
                                data-sub-html="<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolor doloremque hic excepturi fugit, sunt impedit fuga tempora, ad amet aliquid?</p>"
                            >
                                <a href="#">
                                    <img
                                        src="/assets/img/photosen/nature_small_1.jpg"
                                        alt="IMage"
                                        className="img-fluid"
                                    />
                                </a>
                            </div>

                            <div
                                className="col-4 col-sm-4 col-md-4 col-lg-3 col-xl-3 item"
                                data-aos="fade"
                                data-src="/assets/img/photosen/big-images/nature_big_2.jpg"
                                data-sub-html="<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laboriosam omnis quaerat molestiae, praesentium. Ipsam, reiciendis. Aut molestiae animi earum laudantium.</p>"
                            >
                                <a href="#">
                                    <img
                                        src="/assets/img/photosen/nature_small_2.jpg"
                                        alt="IMage"
                                        className="img-fluid"
                                    />
                                </a>
                            </div>

                            <div
                                className="col-4 col-sm-4 col-md-4 col-lg-3 col-xl-3 item"
                                data-aos="fade"
                                data-src="/assets/img/photosen/big-images/nature_big_3.jpg"
                                data-sub-html="<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quidem reiciendis, dolorum illo temporibus culpa eaque dolore rerum quod voluptate doloribus.</p>"
                            >
                                <a href="#">
                                    <img
                                        src="/assets/img/photosen/nature_small_3.jpg"
                                        alt="IMage"
                                        className="img-fluid"
                                    />
                                </a>
                            </div>

                            <div
                                className="col-4 col-sm-4 col-md-4 col-lg-3 col-xl-3 item"
                                data-aos="fade"
                                data-src="/assets/img/photosen/big-images/nature_big_4.jpg"
                                data-sub-html="<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Enim perferendis quae iusto omnis praesentium labore tempore eligendi quo corporis sapiente.</p>"
                            >
                                <a href="#">
                                    <img
                                        src="/assets/img/photosen/nature_small_4.jpg"
                                        alt="IMage"
                                        className="img-fluid"
                                    />
                                </a>
                            </div>

                            <div
                                className="col-4 col-sm-4 col-md-4 col-lg-3 col-xl-3 item"
                                data-aos="fade"
                                data-src="/assets/img/photosen/big-images/nature_big_5.jpg"
                                data-sub-html="<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Saepe, voluptatum voluptate tempore aliquam, dolorem distinctio. In quas maiores tenetur sequi.</p>"
                            >
                                <a href="#">
                                    <img
                                        src="/assets/img/photosen/nature_small_5.jpg"
                                        alt="IMage"
                                        className="img-fluid"
                                    />
                                </a>
                            </div>

                            <div
                                className="col-4 col-sm-4 col-md-4 col-lg-3 col-xl-3 item"
                                data-aos="fade"
                                data-src="/assets/img/photosen/big-images/nature_big_6.jpg"
                                data-sub-html="<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Rerum cum culpa blanditiis illum, voluptatum iusto quisquam mollitia debitis quaerat maiores?</p>"
                            >
                                <a href="#">
                                    <img
                                        src="/assets/img/photosen/nature_small_6.jpg"
                                        alt="IMage"
                                        className="img-fluid"
                                    />
                                </a>
                            </div>

                            <div
                                className="col-4 col-sm-4 col-md-4 col-lg-3 col-xl-3 item"
                                data-aos="fade"
                                data-src="/assets/img/photosen/big-images/nature_big_7.jpg"
                                data-sub-html="<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Asperiores similique impedit possimus, laboriosam enim at placeat nihil voluptatibus voluptate hic!</p>"
                            >
                                <a href="#">
                                    <img
                                        src="/assets/img/photosen/nature_small_7.jpg"
                                        alt="IMage"
                                        className="img-fluid"
                                    />
                                </a>
                            </div>

                            <div
                                className="col-4 col-sm-4 col-md-4 col-lg-3 col-xl-3 item"
                                data-aos="fade"
                                data-src="/assets/img/photosen/big-images/nature_big_8.jpg"
                                data-sub-html="<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quam vitae sed cum mollitia itaque soluta laboriosam eaque sit ratione, aliquid.</p>"
                            >
                                <a href="#">
                                    <img
                                        src="/assets/img/photosen/nature_small_8.jpg"
                                        alt="IMage"
                                        className="img-fluid"
                                    />
                                </a>
                            </div>

                            <div
                                className="col-4 col-sm-4 col-md-4 col-lg-3 col-xl-3 item"
                                data-aos="fade"
                                data-src="/assets/img/photosen/big-images/nature_big_9.jpg"
                                data-sub-html="<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quidem reiciendis debitis beatae facilis quos, enim quis nobis magnam architecto earum!</p>"
                            >
                                <a href="#">
                                    <img
                                        src="/assets/img/photosen/nature_small_9.jpg"
                                        alt="IMage"
                                        className="img-fluid"
                                    />
                                </a>
                            </div>



                            <div
                                className="col-4 col-sm-4 col-md-4 col-lg-3 col-xl-3 item"
                                data-aos="fade"
                                data-src="/assets/img/photosen/big-images/nature_big_1.jpg"
                                data-sub-html="<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolor doloremque hic excepturi fugit, sunt impedit fuga tempora, ad amet aliquid?</p>"
                            >
                                <a href="#">
                                    <img
                                        src="/assets/img/photosen/nature_small_1.jpg"
                                        alt="IMage"
                                        className="img-fluid"
                                    />
                                </a>
                            </div>

                            <div
                                className="col-4 col-sm-4 col-md-4 col-lg-3 col-xl-3 item"
                                data-aos="fade"
                                data-src="/assets/img/photosen/big-images/nature_big_2.jpg"
                                data-sub-html="<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laboriosam omnis quaerat molestiae, praesentium. Ipsam, reiciendis. Aut molestiae animi earum laudantium.</p>"
                            >
                                <a href="#">
                                    <img
                                        src="/assets/img/photosen/nature_small_2.jpg"
                                        alt="IMage"
                                        className="img-fluid"
                                    />
                                </a>
                            </div>

                            <div
                                className="col-4 col-sm-4 col-md-4 col-lg-3 col-xl-3 item"
                                data-aos="fade"
                                data-src="/assets/img/photosen/big-images/nature_big_3.jpg"
                                data-sub-html="<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quidem reiciendis, dolorum illo temporibus culpa eaque dolore rerum quod voluptate doloribus.</p>"
                            >
                                <a href="#">
                                    <img
                                        src="/assets/img/photosen/nature_small_3.jpg"
                                        alt="IMage"
                                        className="img-fluid"
                                    />
                                </a>
                            </div>

                            <div
                                className="col-4 col-sm-4 col-md-4 col-lg-3 col-xl-3 item"
                                data-aos="fade"
                                data-src="/assets/img/photosen/big-images/nature_big_4.jpg"
                                data-sub-html="<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Enim perferendis quae iusto omnis praesentium labore tempore eligendi quo corporis sapiente.</p>"
                            >
                                <a href="#">
                                    <img
                                        src="/assets/img/photosen/nature_small_4.jpg"
                                        alt="IMage"
                                        className="img-fluid"
                                    />
                                </a>
                            </div>

                            <div
                                className="col-4 col-sm-4 col-md-4 col-lg-3 col-xl-3 item"
                                data-aos="fade"
                                data-src="/assets/img/photosen/big-images/nature_big_5.jpg"
                                data-sub-html="<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Saepe, voluptatum voluptate tempore aliquam, dolorem distinctio. In quas maiores tenetur sequi.</p>"
                            >
                                <a href="#">
                                    <img
                                        src="/assets/img/photosen/nature_small_5.jpg"
                                        alt="IMage"
                                        className="img-fluid"
                                    />
                                </a>
                            </div>

                            <div
                                className="col-4 col-sm-4 col-md-4 col-lg-3 col-xl-3 item"
                                data-aos="fade"
                                data-src="/assets/img/photosen/big-images/nature_big_6.jpg"
                                data-sub-html="<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Rerum cum culpa blanditiis illum, voluptatum iusto quisquam mollitia debitis quaerat maiores?</p>"
                            >
                                <a href="#">
                                    <img
                                        src="/assets/img/photosen/nature_small_6.jpg"
                                        alt="IMage"
                                        className="img-fluid"
                                    />
                                </a>
                            </div>

                            <div
                                className="col-4 col-sm-4 col-md-4 col-lg-3 col-xl-3 item"
                                data-aos="fade"
                                data-src="/assets/img/photosen/big-images/nature_big_7.jpg"
                                data-sub-html="<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Asperiores similique impedit possimus, laboriosam enim at placeat nihil voluptatibus voluptate hic!</p>"
                            >
                                <a href="#">
                                    <img
                                        src="/assets/img/photosen/nature_small_7.jpg"
                                        alt="IMage"
                                        className="img-fluid"
                                    />
                                </a>
                            </div>

                            <div
                                className="col-4 col-sm-4 col-md-4 col-lg-3 col-xl-3 item"
                                data-aos="fade"
                                data-src="/assets/img/photosen/big-images/nature_big_8.jpg"
                                data-sub-html="<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quam vitae sed cum mollitia itaque soluta laboriosam eaque sit ratione, aliquid.</p>"
                            >
                                <a href="#">
                                    <img
                                        src="/assets/img/photosen/nature_small_8.jpg"
                                        alt="IMage"
                                        className="img-fluid"
                                    />
                                </a>
                            </div>

                            <div
                                className="col-4 col-sm-4 col-md-4 col-lg-3 col-xl-3 item"
                                data-aos="fade"
                                data-src="/assets/video/news2.mp4"
                                data-sub-html="<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quidem reiciendis debitis beatae facilis quos, enim quis nobis magnam architecto earum!</p>"
                            >
                                <a href="/assets/video/news2.mp4" className="item">
                                    {/* Miniatura del video o cualquier contenido que desees mostrar */}
                                    <video width="100%" height="100%">
                                        <source src="/assets/video/news2.mp4" type="video/mp4" />
                                        Tu navegador no soporta el tag de video.
                                    </video>
                                </a>
                            </div>










                        </div>
                    </div>
                </div>
            </div>



            <Footer />


        </main >



    );

}

export default PhotosenEn;
