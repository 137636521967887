

import React, { useEffect, useState, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';



function MenuMobEs({ changeLanguage, navigateTo }) {

    const navigate = useNavigate();

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [isMenuCollapsed, setMenuCollapsed] = useState(true);


   



    const handleToggleClick = () => {
        setMenuCollapsed(!isMenuCollapsed);

        const navbarCollapse = document.querySelector('.navbar-collapse');
        navbarCollapse.style.display = isMenuCollapsed ? 'block' : 'none';

        const subMenus = document.querySelectorAll('.sub-menuMob');
        subMenus.forEach((submenu) => {
            submenu.style.display = 'none';
        });
    };

    const handleSubMenuClick = (e) => {
        e.preventDefault();
        const submenu = e.target.nextElementSibling;

        const otherSubMenus = document.querySelectorAll('.sub-menuMob');
        otherSubMenus.forEach((otherMenu) => {
            if (otherMenu !== submenu) {
                otherMenu.style.display = 'none';
            }
        });

        if (!isMenuCollapsed && submenu) {
            submenu.style.display = submenu.style.display === 'block' ? 'none' : 'block';
        }


 

    };

    //Colapsar al hacer clic en enlaces
    const handleNavLinkClick = () => {
        setMenuCollapsed(true);

    };

    const handleResize = () => {
        setWindowWidth(window.innerWidth);

        if (window.innerWidth > 750) {
            setMenuCollapsed(true);
        }
    };

    useEffect(() => {
        const resizeHandler = () => {
            clearTimeout(resizeTimer);
            resizeTimer = setTimeout(handleResize, 100);
        };

        let resizeTimer;

        window.addEventListener('resize', resizeHandler);

        return () => {
            window.removeEventListener('resize', resizeHandler);
        };
    }, []);

    useEffect(() => {
        handleResize();
    }, [windowWidth]);

    useEffect(() => {
        // Asegurar que el menú esté colapsado al renderizar
        const navbarCollapse = document.querySelector('.navbar-collapse');
        navbarCollapse.style.display = isMenuCollapsed ? 'none' : 'block';

        const subMenus = document.querySelectorAll('.sub-menuMob');
        subMenus.forEach((submenu) => {
            submenu.style.display = 'none';
        });
    }, [isMenuCollapsed]);



    /* 00000000000000 Move var 0000000000000000000  */

    const [startY, setStartY] = useState(0);
    const [visible, setVisible] = useState(true);

    const handleTouchStart = (e) => {
        setStartY(e.touches[0].clientY);
    };

    const handleTouchMove = (e) => {
        const currentY = e.touches[0].clientY;
        if (startY < currentY && !visible) {
            setVisible(true);
        } else if (startY > currentY && visible) {
            setVisible(false);
        }
    };

    useEffect(() => {
        window.addEventListener('touchstart', handleTouchStart);
        window.addEventListener('touchmove', handleTouchMove);

        return () => {
            window.removeEventListener('touchstart', handleTouchStart);
            window.removeEventListener('touchmove', handleTouchMove);
        };
    }, [startY, visible]);


    return (

        <main>


            {/*================= TOPBARMOB =================*/}

            {/* <div className='topbarmob'> */}
            <div className={`topbarmob ${visible ? 'visible' : 'hidden'}`}>
                {/* <div style={{ display: 'flex', position: 'fixed', width: '100%' ,  justifyContent: 'space-between', alignItems: 'center', backgroundColor: '#333', color: 'white', padding: '10px 20px 5px' }}> */}
                <Link to=""  > <span style={{ color: 'white', fontWeight: 'bold' }}> Pekín </span> </Link>
                {/*  <div style={{ fontSize: '18px' }}> Moscow </div> */}

                <div style={{ fontSize: '20px' }}>

                    <Link to="https://www.youtube.com" target="_blank"> <i className="fab fa-youtube" style={{ color: 'white', marginRight: '10px' }} ></i> </Link>
                    <Link to="https://www.youtube.com" target="_blank"> <i className="fab fa-instagram" style={{ color: 'white', marginRight: '10px' }} ></i> </Link>
                    <Link to="https://www.youtube.com" target="_blank"> <i className="fab fa-telegram" style={{ color: 'white', marginRight: '10px' }} ></i> </Link>
                    <Link to="https://www.youtube.com" target="_blank"> <i className="fab fa-vk" style={{ color: 'white' }} ></i> </Link>

                </div>

                <div style={{ fontSize: '16px' }}>

                    <span role="img" aria-label="language"></span>
                    <select onChange={(e) => changeLanguage(e.target.value)}>

                        <option value="es">Es</option>
                        <option value="ru">中文</option>
                        <option value="en">En</option>
                    </select>
                </div>
            </div>

            {/*================= main bar =================*/}

            {/* <div className='main-wrapper' > */}
            <div className={`main-wrapper ${visible ? 'visible' : 'hidden'}`}>
                {/* <nav className={`navbarmob ${touched ? 'visible' : 'hidden'}`}>*/}

                {/* <nav className="navbarmob"> */}
                <nav className={`navbarmob ${visible ? 'visible' : 'hidden'}`}>

                    <div className="brand-and-icon">
                        {/* <Link to="/" className="navbar-brand"> LATINO</Link> */}
                        < Link to="/" className="navbar-brand">
                            <span style={{ color: 'white' }}>CHINA</span>
                            <span style={{ color: 'red' }}>FABRICANTES</span>
                             
                        </Link>

                        <button type="button" className="navbar-toggler" onClick={handleToggleClick}>
                            <i className="fas fa-bars" />
                        </button>

                    </div>

                    <div className="navbar-collapse">
                        <ul className="navbar-navmob">

                            <li>
                                <Link to="/" onClick={handleSubMenuClick}> Inicio </Link>

                            </li>



                            {/*================= 2 BAILES =================*/}


                            <li>
                                <a href="#" className="menu-link" onClick={handleSubMenuClick}>
                                    Servicios
                                    <span className="drop-icon">
                                        <i className="fas fa-chevron-down" />
                                    </span>
                                </a>
                                <div className="sub-menuMob">
                                    {/* item */}
                                    <div className="sub-menuMob-item">
                                        <h4>top categories</h4>

                                        <ul>

                                            <li><Link to="/" onClick={() => handleNavLinkClick()}> BÚSQUEDA DE PROVEEDORES </Link></li>
                                            <li><Link to="/" onClick={() => handleNavLinkClick()}> ASESORÍA Y LOGÍSTICA DE COMPRAS </Link></li>
                                            <li><Link to="/" onClick={() => handleNavLinkClick()}> CONTROL DE CALIDAD </Link></li>
                                            <li><Link to="/" onClick={() => handleNavLinkClick()}> AGENTES DE COMPRAS </Link></li>
                                            <li><Link to="/" onClick={() => handleNavLinkClick()}> VALIDACIÓN DE FABRICANTES </Link></li>

                                        </ul>
                                    </div>
                                    {/* end of item */}

                                    
                                    {/* item */}
                                    <div className="sub-menuMob-item">
                                        <h2>China al alcance de tu mano</h2>
                                        <div className="imagemob">
                                            <img
                                                src="/assets/img/gallery/post-29.jpg"
                                                alt="Dance image"
                                            /> </div>

                                        <button type="button" className="btn">
                                            <Link to="/" onClick={() => handleNavLinkClick()}> Conctáctanos! </Link>
                                        </button>
                                    </div>
                                    {/* end of item */}


                                </div>
                            </li>


                            {/*=============== 3 Proveedores =================*/}

                            <li>
                                <a href="#" className="menu-link" onClick={handleSubMenuClick}>
                                    Búsqueda de Proveedores
                                    <span className="drop-icon">
                                        <i className="fas fa-chevron-down" />
                                    </span>
                                </a>
                                <div className="sub-menuMob">
                                    {/* item */}
                                  <div className="sub-menuMob-item">
                                     <h4>top categories</h4>
                                       <ul>
                                          <li><Link to="/" onClick={() => handleNavLinkClick()}> Los mejores Proveedores Latinos </Link></li>
                                            
                                            <li>
                                                <a href="#">Clubes y pubs</a>
                                            </li>
                                            <li>
                                                <a href="#">Descuentos y promociones</a>
                                            </li>
                                            <li>
                                                <a href="#">Recomendados</a>
                                            </li>
                                        </ul>
                                    </div>
                                    {/* end of item */}

                                    {/* item */}
                                    <div className="sub-menuMob-item">
                                        <h2>Fusión de </h2>
                                        <div className="imagemob">
                                            <img
                                                src="/assets/img/restaurant/foodie-food.gif"
                                                alt="product image"
                                            /> </div>

                                        <button type="button" className="btn">
                                            <Link to="/" onClick={() => handleNavLinkClick()}> Delicioso! </Link>
                                        </button>
                                    </div>

                                </div>
                            </li>

                            {/*=============== 4 IDIOMAS ====================*/}

                            <li>
                                <a href="#" className="menu-link" onClick={handleSubMenuClick}>
                                Directorio de proveedores
                                    <span className="drop-icon">
                                        <i className="fas fa-chevron-down" />
                                    </span>
                                </a>
                                <div className="sub-menuMob">
                                    {/* item */}
                                    <div className="sub-menuMob-item">
                                        <h4>Escuelas  </h4>
                                        <ul>
                                            <li>
                                                {/* <a href="#">Escuelas de Español</a> */}
                                                <Link to="/" onClick={() => handleNavLinkClick()}> Escuelas   </Link>
                                            </li>

                                            <li>
                                                {/* <a href="#">Profesores de Español</a> */}
                                                <Link to="/" onClick={() => handleNavLinkClick()}> Asesores </Link>
                                            </li>
                                            <li>

                                                <Link to="/" onClick={() => handleNavLinkClick()}> Eventos   </Link>

                                            </li>
                                            <li>

                                                <Link to="/" onClick={() => handleNavLinkClick()}> Inmersión   </Link>
                                            </li>
                                            <li>
                                                <a href="#">Herramientas y Ferretería y documentación</a>
                                            </li>

                                        </ul>
                                    </div>
                                    {/* end of item */}

                                    {/* item */}
                                    <div className="sub-menuMob-item">
                                        <h2>El  idioma   </h2>
                                        <div className="imagemob">
                                            <img
                                                src="/assets/img/gallery/post-48.jpg"
                                                alt="product image"
                                            /> </div>

                                        <button type="button" className="btn">

                                            <Link to="/" onClick={() => handleNavLinkClick()}> Hablemos Español! </Link>
                                        </button>
                                    </div>

                                </div>
                            </li>

                            {/*=============== 5 LOOK====================*/}

                            <li>
                                <a href="#" className="menu-link" onClick={handleSubMenuClick}>
                                    TENDENCIA
                                    <span className="drop-icon">
                                        <i className="fas fa-chevron-down" />
                                    </span>
                                </a>
                                <div className="sub-menuMob">
                                    {/* item */}
                                    <div className="sub-menuMob-item">
                                        <h4>top categories</h4>
                                        <ul>
                                            <li>
                                                <a href="#">Moda y belleza</a>
                                            </li>

                                            <li>
                                                <a href="#">Soy Latin❤️</a>
                                            </li>
                                            <li>
                                                <a href="#">Productos</a>
                                            </li>
                                            <li>
                                                <a href="#">Ferias y Exposiciones</a>
                                            </li>
                                            <li>
                                                <a href="#">Promociones para Miembros</a>
                                            </li>
                                        </ul>
                                    </div>
                                    {/* end of item */}


                                    {/* item */}
                                    <div className="sub-menuMob-item">
                                        <h2>Eres   </h2>
                                        <div className="imagemob">
                                            <img
                                                src="/assets/img/gallery/rusia-identi-digital.jpg"
                                                alt="product image"
                                            /> </div>

                                        <button type="button" className="btn">
                                            <Link to="/" onClick={() => handleNavLinkClick()}>  Esto es para ti! </Link>
                                        </button>

                                    </div>

                                </div>
                            </li>

                            {/*=============== 6 ACTUALIDAD ===================*/}

                            <li>
                                <a href="#" className="menu-link" onClick={handleSubMenuClick}>
                                    Sociedad
                                    <span className="drop-icon">
                                        <i className="fas fa-chevron-down" />
                                    </span>
                                </a>
                                <div className="sub-menuMob">
                                    {/* item */}
                                    <div className="sub-menuMob-item">
                                        <h4>top categories</h4>
                                        <ul>
                                            <li>
                                                <a href="#">Embajadas</a>
                                            </li>
                                            <li>
                                                <a href="#">Visas para China</a>
                                            </li>
                                            <li>
                                                <a href="#">Negocios</a>
                                            </li>
                                            <li>
                                                <a href="#">Envíos & Transacciones</a>
                                            </li>

                                            <li>
                                                <a href="#">Tengo Un Plan</a>
                                            </li>

                                        </ul>
                                    </div>
                                    {/* end of item */}

                                    {/* item */}
                                    <div className="sub-menuMob-item">
                                        <h2>Explora y vive</h2>
                                        <div className="imagemob">
                                            <img
                                                src="/assets/img/gif/dominican-republic.gif"
                                                alt="product image"
                                            /> </div>

                                        <button type="button" className="btn">
                                            <Link to="/" onClick={() => handleNavLinkClick()}>   Viajemos! </Link>
                                        </button>
                                    </div>


                                </div>
                            </li>

                            {/*=============== 7 ABOUT ===================*/}

                            <li>
                                <a href="#" className="menu-link" onClick={handleSubMenuClick}>
                                    HOLA
                                    <span className="drop-icon">
                                        <i className="fas fa-chevron-down" />
                                    </span>
                                </a>
                                <div className="sub-menuMob">
                                    {/* item */}
                                    <div className="sub-menuMob-item">
                                        <h4>top categories</h4>
                                        <ul>

                                            <li>
                                                <a href="#">CLUB - MIEMBROS</a>
                                            </li>
                                            <li>
                                                <a href="#">Support Us</a>
                                            </li>
                                            <li>
                                                <a href="#">Soluciones Digitales</a>
                                            </li>
                                            <li>
                                                <a href="#"> Acerca de </a>
                                            </li>

                                        </ul>
                                    </div>
                                    {/* end of item */}

                                    {/* item */}
                                    <div className="sub-menuMob-item">
                                        <h2>Comunidad </h2>
                                        <div className="imagemob">
                                            <img
                                                src="/assets/img/gif/latino-flags.gif"
                                                alt="product image"
                                            /> </div>

                                        <button type="button" className="btn">
                                            <Link to="/" onClick={() => handleNavLinkClick()}>  Gracias! </Link>
                                        </button>
                                    </div>

                                </div>
                            </li>

                            {/*=============== 6 OFERTAS ====================*/}

                            <li>
                                <a href="#" onClick={handleSubMenuClick} >Foro</a>
                            </li>
                        </ul>
                    </div>

                </nav>
            </div>

            {/*  </div> */}

        </main>

    );
}

export default MenuMobEs;