

/* WebUNI */

import React, { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

 import Footer from './FooterEn';
 
import { Link } from 'react-router-dom';



function Webuni() {

    return (

        <main>
 
            <section > 

                {/* Hero section */}

                 <section className="hero-section set-bg" style={{ backgroundImage: 'url("./assets/img/china/china-tower.png")' }}>

                    <div className="container">
                        <div className="hero-text text-white">
                            <h2>Direct trade from China</h2>
                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
                                malesuada lorem maximus mauris scelerisque, at rutrum nulla <br />{" "}
                                dictum. Ut ac ligula sapien. Suspendisse cursus faucibus finibus.
                            </p>
                        </div>
                        <div className="row">
                            <div className="col-lg-10 offset-lg-1">
                                <form className="intro-newslatter">
                                    <input type="text" placeholder="Name" />
                                    <input type="text" className="last-s" placeholder="E-mail" />
                                    <button className="site-btn">Sign Up Now</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
                {/* Hero section end */}


                {/* categories section */}
                <section className="categories-section spad">
                    <div className="container">
                        <div className="section-title-webuni"> 
                            <h2>Categories</h2>
                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
                                malesuada lorem maximus mauris scelerisque, at rutrum nulla dictum. Ut
                                ac ligula sapien. Suspendisse cursus faucibus finibus.
                            </p>
                        </div>
                        <div className="row">
                            {/* categorie */}
                            <div className="col-lg-4 col-md-6">
                                <div className="categorie-item">
                                    <div className="ci-thumb set-bg">
                                        <img src="assets/img/webuni/categories/1.jpg" />
                                    </div>

                                    <div className="ci-text">
                                        <h5>Technology</h5>
                                        <p>Lorem ipsum dolor sit amet, consectetur</p>
                                        <span>600 Productos</span>
                                    </div>
                                </div>
                            </div>
                            {/* categorie */}
                            <div className="col-lg-4 col-md-6">
                                <div className="categorie-item">
                                    <div className="ci-thumb set-bg">
                                        <img src="assets/img/china/maquinas.png" />
                                    </div>
                                    <div className="ci-text">
                                        <h5>Industrial machinery</h5>
                                        <p>Lorem ipsum dolor sit amet, consectetur</p>
                                        <span>700 Productos</span>
                                    </div>
                                </div>
                            </div>
                            {/* categorie */}
                            <div className="col-lg-4 col-md-6">
                                <div className="categorie-item">

                                    <div className="ci-thumb set-bg">
                                        <img src="assets/img/webuni/categories/3.jpg" />
                                    </div>
                                    <div className="ci-text">
                                        <h5>Herramientas y Ferretería </h5>
                                        <p>Lorem ipsum dolor sit amet, consectetur</p>
                                        <span>550 Productos</span>
                                    </div>
                                </div>
                            </div>
                            {/* categorie */}
                            <div className="col-lg-4 col-md-6">
                                <div className="categorie-item">
                                    <div className="ci-thumb set-bg">
                                        <img src="assets/img/china/sport-product.png" />
                                    </div>
                                    <div className="ci-text">
                                        <h5>Artículos Deportivos</h5>
                                        <p>Lorem ipsum dolor sit amet, consectetur</p>
                                        <span>450 Productos</span>
                                    </div>
                                </div>
                            </div>
                            {/* categorie */}
                            <div className="col-lg-4 col-md-6">
                                <div className="categorie-item">
                                    <div className="ci-thumb set-bg">
                                        <img src="assets/img/webuni/categories/5.jpg" />
                                    </div>

                                    <div className="ci-text">
                                        <h5>Ropa y Accesorios</h5>
                                        <p>Lorem ipsum dolor sit amet, consectetur</p>
                                        <span>220 Productos</span>
                                    </div>
                                </div>
                            </div>
                            {/* categorie */}
                            <div className="col-lg-4 col-md-6">
                                <div className="categorie-item">
                                    <div className="ci-thumb set-bg">
                                        <img src="assets/img/webuni/categories/6.jpg" />
                                    </div>

                                    <div className="ci-text">
                                        <h5>Agricultura y Alimentos</h5>
                                        <p>Lorem ipsum dolor sit amet, consectetur</p>
                                        <span>250 Productos</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* categories section end */}


                {/* search section */}
                <section className="search-section">
                    <div className="container">
                        <div className="search-warp">
                            <div className="section-title-webuni text-white">
                                <h2>Busca tu producto </h2>
                            </div>
                            <div className="row">
                                <div className="col-md-10 offset-md-1">
                                    {/* search form */}
                                    <form className="course-search-form">
                                        <input type="text" placeholder="Course" />
                                        <input type="text" className="last-m" placeholder="Category" />
                                        <button className="site-btn">Search Product</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* search section end */}
                {/* course section */}
                <section className="course-section spad">
                    <div className="container">
                        <div className="section-title-webuni mb-0">
                            <h2>Productos Relacionados</h2>
                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
                                malesuada lorem maximus mauris scelerisque, at rutrum nulla dictum. Ut
                                ac ligula sapien. Suspendisse cursus faucibus finibus.
                            </p>
                        </div>
                    </div>
                    <div className="course-warp">
                        <ul className="course-filter controls">
                            <li className="control active" data-filter="all">
                                Todos
                            </li>
                            <li className="control" data-filter=".finance">
                                Tecnología
                            </li>
                            <li className="control" data-filter=".design">
                                Maquinaria
                            </li>
                            <li className="control" data-filter=".web">
                                Móviles
                            </li>
                            <li className="control" data-filter=".photo">
                                Ofertas
                            </li>
                        </ul>
                        <div className="row course-items-area">
                            {/* course */}
                            <div className="mix col-lg-3 col-md-4 col-sm-6 finance">
                                <div className="course-item">
                                 
                                 
                                    <div className="course-thumb set-bg" style={{ backgroundImage: 'url("./assets/img/webuni/courses/1.jpg")' }}>
                                        <div className="price">Precio: Desde $100 </div>
                                    </div>

                                    <div className="course-info">
                                        <div className="course-text">
                                            <h5>Equipos Industriales</h5>
                                            <p>Lorem ipsum dolor sit amet, consectetur</p>
                                            <div className="students">250 Productos</div>
                                        </div>
                                        <div className="course-author">

                                            <div className="ca-pic set-bg" style={{ backgroundImage: 'url("./assets/img/webuni/authors/1.jpg")' }} />

                                            <p>
                                                Ana Lucia, <span> Asesor de Ventas </span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* course */}
                            <div className="mix col-lg-3 col-md-4 col-sm-6 design">
                                <div className="course-item">

                                    <div className="course-thumb set-bg" style={{ backgroundImage: 'url("./assets/img/webuni/courses/2.jpg")' }}>
                                        <div className="price">Precio: Desde $100 </div>
                                    </div>
                                    <div className="course-info">
                                        <div className="course-text">
                                            <h5>Metalurgia, Mineral y Energía</h5>
                                            <p>Lorem ipsum dolor sit amet, consectetur</p>
                                            <div className="students">250 Productos</div>
                                        </div>
                                        <div className="course-author">
                                            <div className="ca-pic set-bg" style={{ backgroundImage: 'url("./assets/img/webuni/authors/2.jpg")' }} />

                                            <p>
                                                William Parker, <span>Asesor de Ventas </span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* course */}
                            <div className="mix col-lg-3 col-md-4 col-sm-6 web">
                                <div className="course-item">

                                    <div className="course-thumb set-bg" style={{ backgroundImage: 'url("./assets/img/webuni/courses/3.jpg")' }}>
                                        <div className="price">Precio: Desde $100 </div>
                                    </div>

                                    <div className="course-info">
                                        <div className="course-text">
                                            <h5>Automotriz</h5>
                                            <p>Lorem ipsum dolor sit amet, consectetur</p>
                                            <div className="students">650 Productos</div>
                                        </div>
                                        <div className="course-author">

                                            <div className="ca-pic set-bg" style={{ backgroundImage: 'url("./assets/img/webuni/authors/3.jpg")' }} />

                                            <p>
                                                William Parker, <span>Asesor de Ventas </span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* course */}
                            <div className="mix col-lg-3 col-md-4 col-sm-6 photo">
                                <div className="course-item">

                                    <div className="course-thumb set-bg" style={{ backgroundImage: 'url("./assets/img/webuni/courses/5.jpg")' }}>
                                        <div className="price">Precio: Desde $100 </div>
                                    </div>

                                    <div className="course-info">
                                        <div className="course-text">
                                            <h5>Electricidad y Electrónica</h5>
                                            <p>Lorem ipsum dolor sit amet, consectetur</p>
                                            <div className="students">300 Productos</div>
                                        </div>
                                        <div className="course-author">
                                            <div className="ca-pic set-bg" style={{ backgroundImage: 'url("./assets/img/webuni/authors/5.jpg")' }} />

                                            <p>
                                                William Parker, <span>Asesor de Ventas </span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* course */}
                            <div className="mix col-lg-3 col-md-4 col-sm-6 finance">
                                <div className="course-item">

                                    <div className="course-thumb set-bg" style={{ backgroundImage: 'url("./assets/img/webuni/courses/4.jpg")' }}>
                                        <div className="price">Precio: Desde $100 </div>
                                    </div>

                                    <div className="course-info">
                                        <div className="course-text">
                                            <h5>Seguridad y Protección</h5>
                                            <p>Lorem ipsum dolor sit amet, consectetur</p>
                                            <div className="students">250 Productos</div>
                                        </div>
                                        <div className="course-author">
                                            <div className="ca-pic set-bg" style={{ backgroundImage: 'url("./assets/img/webuni/authors/4.jpg")' }} />

                                            <p>
                                                William Parker, <span>Asesor de Ventas </span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* course */}
                            <div className="mix col-lg-3 col-md-4 col-sm-6 design">
                                <div className="course-item">

                                    <div className="course-thumb set-bg" style={{ backgroundImage: 'url("./assets/img/webuni/courses/6.jpg")' }}>
                                        <div className="price">Precio: Desde $100 </div>
                                    </div>

                                    <div className="course-info">
                                        <div className="course-text">
                                            <h5>Herramientas y Ferretería</h5>
                                            <p>Lorem ipsum dolor sit amet, consectetur</p>
                                            <div className="students">250 Productos</div>
                                        </div>
                                        <div className="course-author">
                                            <div className="ca-pic set-bg" style={{ backgroundImage: 'url("./assets/img/webuni/authors/6.jpg")' }} />

                                            <p>
                                                William Parker, <span>Asesor de Ventas </span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* course */}
                            <div className="mix col-lg-3 col-md-4 col-sm-6 web">
                                <div className="course-item">

                                    <div className="course-thumb set-bg" style={{ backgroundImage: 'url("./assets/img/webuni/courses/7.jpg")' }}>
                                        <div className="price">Precio: Desde $100 </div>
                                    </div>

                                    <div className="course-info">
                                        <div className="course-text">
                                            <h5>Negocios</h5>
                                            <p>Lorem ipsum dolor sit amet, consectetur</p>
                                            <div className="students">250 Productos</div>
                                        </div>
                                        <div className="course-author">
                                            <div className="ca-pic set-bg" style={{ backgroundImage: 'url("./assets/img/webuni/authors/7.jpg")' }} />

                                            <p>
                                                William Parker, <span>Asesor de Ventas </span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* course */}
                            <div className="mix col-lg-3 col-md-4 col-sm-6 photo">
                                <div className="course-item">

                                    <div className="course-thumb set-bg" style={{ backgroundImage: 'url("./assets/img/webuni/courses/8.jpg")' }}>
                                        <div className="price">Precio: Desde $100 </div>
                                    </div>

                                    <div className="course-info">
                                        <div className="course-text">
                                            <h5>Viajes</h5>
                                            <p>Lorem ipsum dolor sit amet, consectetur</p>
                                            <div className="students">250 Productos</div>
                                        </div>
                                        <div className="course-author">
                                            <div className="ca-pic set-bg" style={{ backgroundImage: 'url("./assets/img/webuni/authors/8.jpg")' }} />

                                            <p>
                                                William Parker, <span>Asesor de Ventas </span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* course section end */}
                {/* signup section */}
                <section className="signup-section spad">
                    <div className="signup-bg set-bg" style={{ backgroundImage: 'url("./assets/img/china/china-container.png")' }}>

                    </div>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="signup-warp">
                                    <div className="section-title-webuni text-white text-left">
                                        <h2>Regístrate para información personalizada</h2>
                                        <p>
                                            Puedes llenar tus datos en este formulario o escribirmos a contact@chinafabricantes.com.
                                        </p>
                                    </div>
                                    {/* signup form */}
                                    <form className="signup-form">
                                        <input type="text" placeholder="Your Name" />
                                        <input type="text" placeholder="Your E-mail" />
                                        <input type="text" placeholder="Your Phone" />
                                        <label htmlFor="v-upload" className="file-up-btn">
                                            Upload
                                        </label>
                                        <input type="file" id="v-upload" />
                                        <button className="site-btn">Enviar</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* signup section end */}

                
                {/* banner section */}
                <section className="banner-section spad">
                    <div className="container">
                        <div className="section-title-webuni mb-0 pb-2">
                            <h2>Únete a nuestra comunidad ahora!</h2>
                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
                                malesuada lorem maximus mauris scelerisque, at rutrum nulla dictum. Ut
                                ac ligula sapien. Suspendisse cursus faucibus finibus.
                            </p>
                        </div>
                        <div className="text-center pt-5">
                            <a href="#" className="site-btn">
                                Regístrate
                            </a>
                        </div>
                    </div>
                </section>               
               
            </section >


            <Footer />

        </main>

    );

}

export default Webuni;